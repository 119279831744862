import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M32.63 59.70L28.02 59.70L28.02 52.91L28.02 52.91Q24.01 61.14 17.33 61.14L17.33 61.14L17.33 61.14Q10.83 61.14 7.03 54.42L7.03 54.42L7.03 54.42Q3.30 47.71 3.30 34.56L3.30 34.56L3.30 34.56Q3.30 22.22 7.21 14.98L7.21 14.98L7.21 14.98Q11.07 7.84 18.28 7.84L18.28 7.84L18.28 7.84Q29.85 7.84 32.13 23.70L32.13 23.70L26.23 23.70L26.23 23.70Q24.61 13.25 18.28 13.25L18.28 13.25L18.28 13.25Q9.49 13.25 9.49 34.42L9.49 34.42L9.49 34.42Q9.49 55.72 18 55.72L18 55.72L18 55.72Q25.73 55.72 27.39 40.89L27.39 40.89L19.79 40.89L19.79 35.72L32.63 35.72L32.63 59.70ZM40.54 59.70L40.54 9.28L46.16 9.28L46.16 33.33L60.79 9.28L67.75 9.28L55.72 28.72L69.57 59.70L62.58 59.70L52.00 33.47L46.16 42.26L46.16 59.70L40.54 59.70ZM78.61 63.63L78.61 54L88.24 54L88.24 63.63L78.61 63.63Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
